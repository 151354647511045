<template>
  <div>
    <v-breadcrumbs
      :items="object"
      divider="/"
      class="caption ma-0 pa-0"
    ></v-breadcrumbs>
  </div>
</template>

<script>
    export default {
        props: {
            object: {}
        },
    }
</script>
