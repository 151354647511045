<template>
  <v-app id="inspire">
    <v-row dense>
        <v-col cols="8" class="hidden-sm-and-down">
            <v-card 
                height="100%" 
                :style="images.back"
            >
            </v-card>                
        </v-col>
        <v-col class="text-center white">
            <img src="img/logo.png" width="200" style="margin-top: 200px;">
               
            <v-form ref="form" class="pa-12">
                <v-text-field
                    outlined
                    append-icon="mdi-email"
                    v-model="formData.email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    class="rounded-0"
                >
                </v-text-field>

                <v-text-field v-if="!forgetpassword"
                    v-model="formData.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Senha"
                    autocomplete="off"
                    @click:append="show1 = !show1"
                    outlined
                    class="rounded-0"
                ></v-text-field>
                    
                <v-btn
                    class="caption font-weight-bold rounded-0"
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    large
                    block
                    @click="validate"
                    v-if="!forgetpassword"
                    elevation="0"
                    
                >
                    Entrar
                </v-btn>
            </v-form>    

            <p class="body-2 caption"> {{ new Date().getFullYear() }} — AYT Site e Sistemas </p>        
        </v-col>
    </v-row>
    
    <vue-snotify></vue-snotify>
        
  </v-app>
</template>

<script>
import axios  from 'axios'

export default {
    created(){
        this.closeRequestPassword()
    },

    data () {   
        return {
            loading: false,

            images: {
                logo: 'logo.png',
                back: `background: url('./img/welcome.jpg') no-repeat center center; background-size: cover;`
            },

            formData: {
                email: '',
                password: '',
            },
            
            show1: false,
            forgetpassword: false,
            sendemail: false,

            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail is not valid',
            ],

            passwordRules: [
                v => !!v || 'Password required',
            ],   
        }
    },
    
    methods: {
        login () {
            this.loading = true

            this.formData.device_name = this.$browserDetect.meta.ua
            this.$store.dispatch('login', this.formData)    
                        .then(() => {
                            this.$router.push({name: 'admin.dashboard'})
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('E-mail ou senha incorreto', error)
                        })
        },

        validate () {
            if (this.$refs.form.validate()) {
              this.snackbar = true
              this.login()
            }
        },

        validate2 () {
            if (this.$refs.form.validate()) {
              this.snackbar = true
              this.SendRequestPassword()
            }
        },

        ForgetPassword(){
            this.forgetpassword = !this.forgetpassword
        },

        SendRequestPassword(){
            this.$store.dispatch('sendRequestPassword', this.formData)
                        .then(() => {
                           this.$snotify.success('Verifique seu e-mail. Solicitaçāo enviada')
                           this.ForgetPassword()
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('erro', error)
                        })
        },

        closeRequestPassword(){
            if(this.$router.currentRoute.query.request && this.$router.currentRoute.query.request != ''){
                const params = {}
                params.item_id = this.$router.currentRoute.query.request 

                this.$store.dispatch('closeRequestPassword', params)
                        .then(() => {
                           this.$snotify.success('Verifique seu e-mail. Uma nova senha foi enviada')
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('erro', error)
                        })
            }
        }        

    }
}
</script>

<style scoped>
#inspire {
  background-color:#f1f4f6;
  font-family: 'Verdana';
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 50%;
}

</style>
