<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>  

        <v-row class="mt-2">
            <v-col>
                <v-card
                    outlined
                    class="pa-3"
                >   
                   <filter-component class="mt-2" @callLoadItems="loadItems" :object="params"></filter-component>    
                </v-card>
            </v-col>
        </v-row>  
         
        <div>
          <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Mensalidade
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent
    },
    
    created() {
        this.loadItems()
    },

    computed: {
        listitems() {
            return this.$store.state.accounts.items    
        },
    },    

    data () {
        return {
            item: {},

            dialog: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Mensalidades',
                    disabled: true,
                    href: 'accounts',
                },
            ],

            panel_search: [],

            params: {},

        }
    },


    methods: {
       
        loadItems (params = '') {

            if(this.$route.params.due_date_from && this.$route.params.due_date_from != ''){
                this.params.due_date_from = this.$route.params.due_date_from
                this.$route.params.due_date_from = ''
            }

            if(this.$route.params.due_date_to && this.$route.params.due_date_to != ''){
                this.params.due_date_to = this.$route.params.due_date_to
                this.$route.params.due_date_to = ''
            }

            if(this.$route.params.status_payment && this.$route.params.status_payment != ''){
                this.params.status_payment = this.$route.params.status_payment
                this.$route.params.status_payment = ''
            }

            if(params != ''){
                this.params = {} 
                this.params = params
                this.$route.params.due_date_from = ''
                this.$route.params.due_date_to = '' 
            }
            this.$store.dispatch('loadAccounts', this.params) 
        },

        editItem (id) {    
            this.$store.dispatch('loadAccount', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },

        loadItem(id){
            this.$store.dispatch('loadAccount', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        openCloseDialog(){
            this.item = {}
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.loadItems(this.params)
            }
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>