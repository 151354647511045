import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth.js'
import preloader from './modules/preloader/preloader.js'
import uploads from './modules/uploads/uploads.js'
import changepassword from './modules/changepassword/changepassword.js'
import users from './modules/users/users.js'
import tenants from './modules/tenants/tenants.js'
import ceps from './modules/ceps/ceps.js'
import texts from './modules/texts/texts.js'
import dashboard from './modules/dashboard/dashboard.js'
import comments from './modules/comments/comments.js'
import closerequestpassword from './modules/closerequestpassword/closerequestpassword.js'
import download from './modules/download/download.js'
import events from './modules/events/events.js'
import accounts from './modules/accounts/accounts.js'
import account_values from './modules/account_values/account_values.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        preloader,
        uploads,
        changepassword,
        users,
        tenants,
        ceps,
        texts,
        dashboard,
        comments,
        closerequestpassword,
        download,
        events,
        accounts,
        account_values
    }
})

export default store
