<template>
    <div>
        <v-divider></v-divider>
        <v-list
            nav
            dense
            class="mt-8"
        >
            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
                <v-list-item
                    v-for="(item, i) in single_items"
                    :key="i"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="redirect(item.link,item.params)">
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                    
                </v-list-item>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-login-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title @click="logout()">Sair</v-list-item-title>
                    </v-list-item-content>
                    
                </v-list-item>

            </v-list-item-group>
        </v-list>

    </div>
</template>

<script>
var moment = require('moment')

export default {
    props: {
        object: {
            require: false,
        },
    }, 

    data () {
        return {
            selectedItem: '',
            single_items: [
                {id:0, text: 'Inicial', icon: 'mdi-home', link: 'admin.dashboard', params:{} },
                {id:2, text: 'Mensalidades', icon: 'mdi-account-cash-outline', link: 'admin.accounts.index', params:{ due_date_from: moment().format("01/MM/YYYY"), due_date_to: moment().endOf('month').format("DD/MM/YYYY")} },
                {id:3, text: 'Clientes', icon: 'mdi-account-group-outline', link: 'admin.tenants.index', params:{} },
                {id:4, text: 'Usuários', icon: 'mdi-account', link: 'admin.users.index', params:{} },
            ],
        }
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },


    methods: {
        logout () {            
            this.$store.dispatch('logout')
        },

        redirect(link_name, params){
            this.$router.push({name: link_name, params: params})
        },

        firstDayOfMonth(){
            var result = moment().format("01/MM/YYYY")
            return result
        },
    },

}
</script>

<style >
.nav-link {
    text-decoration: none;
    color: black;
}

.bg-active {
  background-color: #E3F2FD;
}

</style>