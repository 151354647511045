<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col>
                <v-card
                    outlined
                    class="pa-0 ma-0"
                >        
                    <v-expansion-panels class="pa-0 ma-0" flat v-model="panel_search" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header hide-actions>
                                <bread-crumb-component :object="breadcrumbs"></bread-crumb-component>     
                                
                                <span  v-if="me.profile == 'Master'" class="text-right">
                                    <v-tooltip left class="text-right">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="pink">mdi-filter</v-icon>
                                        </template>
                                        <span>Filtros</span>
                                    </v-tooltip>   
                                </span> 
                            </v-expansion-panel-header>

                            <v-expansion-panel-content  v-if="me.profile == 'Master'">   
                                <filter-component @callLoadItems="loadItems"></filter-component>    
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Usuário
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


        <!-- Icon new -->
        <v-tooltip left v-if="me.profile == 'Master'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab dark color="pink" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>New</span>
        </v-tooltip>
        <!-- End Icon new -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import FilterComponent from './FilterComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        FilterComponent,
        ReportComponent,
        InsertEditComponent
    },
    
    created() {
        this.loadItems()
    },

    computed: {
        listitems() {
            return this.$store.state.users.items    
        },

        me () {
          return this.$store.state.auth.me
        },

        store_user() {
            return this.$store.state.users.store    
        },
    },    

    data () {
        return {
            item: {},

            dialog: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Usuários',
                    disabled: true,
                    href: 'users',
                },
            ],

            panel_search: [],

            params: {
                id: ''
            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
       
        loadItems (params = '') {
            this.$store.commit('STORE_USER', false)

            if(this.params.codes && this.params.codes != ''){
                this.params.page = 1
            }
            if(this.$route.params.id && this.$route.params.id != ''){
                this.params.id = this.$route.params.id
                this.$route.params.id = ''
            }
            if(this.$route.params.days && this.$route.params.days > 0){
                this.params.days = this.$route.params.days
                this.$route.params.days = ''
            }
            if(this.$route.params.renewal_open && this.$route.params.renewal_open != ""){
                this.params.renewal_open = this.$route.params.renewal_open
                this.$route.params.renewal_open = ''
            }

            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
                this.$route.params.renewal_open = ''
                this.$route.params.days = ''
            }
            this.$store.dispatch('loadUsers', this.params) 
            //this.generateRenewalExcel()
            //this.generateRenewalExcel()
        },

        newItem(){
            this.item = {}
            this.dialog = true
        },

        loadItem(id){
            this.$store.dispatch('loadUser', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {    
            this.$store.dispatch('loadUser', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },

        generateRenewalExcel(){
            this.params.paginate = 0
            this.$store.dispatch('loadRenewalProductProjectsExcel', this.params)
                            .then(response => {
                                this.excel.data = response.data
                            })

            this.params.paginate = 10;
        },

        openCloseDialog(){
            if(this.item.id != undefined && this.store_user == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_USER', false)
            }
            this.item = {}
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.loadItems(this.params)
            }
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>