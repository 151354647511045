<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col v-if="item.id" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.due_day"
                        :items="items.due_day"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vencimento</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.payment_method"
                        :items="items.payment_method"
                        outlined
                        :rules="[rules.required]"
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Forma de PGTO</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                        v-model="item.app_plan_id"
                        :items="items.app_plan_id"
                        :rules="[rules.required]"
                        item-text="description"
                        item-value="value"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Plano</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.typeclient"
                        :items="items.typeclient"
                        outlined
                        dense
                        :class="$css_field"
                        @change="setTypeClient"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo cliente</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="item.typeclient == 'PF'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.invoice_doc"
                        v-mask="['###.###.###-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">DOC - NF</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.typeclient == 'PJ'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.invoice_doc"
                        v-mask="['##.###.###/####-##']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">DOC - NF</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.invoice_name"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome - Nota Fiscal</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome_cobranca"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Contato para cobrança</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>    
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        :rules="[rules.required]"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                    <v-text-field
                        v-model="item.endereco"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.cidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone1"
                        :rules="[rules.required]"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 1</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email1"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email2"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="!item.id" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.url"
                        label="URL"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                    </v-text-field>
                </v-col> 
            </v-row> 

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>   
        <span class="white--text">{{ endereco }}</span> 
          
    </div>
</template>

<script>

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },
    },

    data () {
        return {
            endereco: '',

            item: {
                typeclient: 'PF'
            },

            rules: {
                required: value => !!value || 'Obrigatorio',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_tenants: {
                paginate: 'false'
            },

            items: {
                status: ['Pendente','Ativo','Inativo','Suspenso'],
                typeclient:['PF','PJ'],
                due_day:[5,10,15,20,25],
                payment_method: ['Boleto','Pix','Depósito'],
                app_plan_id: [
                    { value: '964ceb16-41f1-4da8-a6bf-9bd5338c4c54', description: '3 usuários, 500 imóveis, 100 condomínios, 1000 facs'},
                    { value: 'd271b5af-55ae-4afd-962c-aaca300445f9', description: '20 usuários, 10000 imóveis, 1000 condomínios, 10000 facs'},
                ]
            }
        }
    },

    methods: {

        setTypeClient(){
            this.item.invoice_doc = ''
        },

        setObject(){
            this.item = this.object
            if(!this.item.id){
                this.item.typeclient = 'PF'
            }
        },

        searchCep(value){
            this.setNull()
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setNull(){
            this.item.endereco = 'Loading...'
            this.item.bairro = 'Loading...'
            this.item.cidade = 'Loading...'
            this.item.estado = ''
        },

        setAddress(address){
            this.endereco = address.logradouro
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const data = item
            this.$delete(data, "app_plan");
            this.$delete(data, "json_account_values");
            this.$delete(data, "modelo_codigo");
            this.$delete(data, "nome");
            this.$delete(data, "razao_social");
            this.$delete(data, "updated_at");
            this.$delete(data, "created_at");
            this.$delete(data, "creci");
            if(item.id){
                this.$delete(data, "url");
            }
          

            let action = item.id ? 'updateTenant' : 'storeTenant'
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>

<style scoped>

</style>