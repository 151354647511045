<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 

        >
            <v-tab href="#tab-1" class="caption primary--text"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption primary--text"> Detalhes da cobrança</v-tab>
            <v-tab href="#tab-3" class="caption primary--text"> Comentários</v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <account-value-component :object="account_values" @callLoadItem="loadItem"></account-value-component>
                </v-card>    
            </v-tab-item>                

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <comment-component :object="comments" @callLoadItem="loadItem"></comment-component>
                </v-card>    
            </v-tab-item> 
        </v-tabs-items>
    </div>    
</template>
<script>
import MainComponent from './MainComponent.vue'
import AccountValueComponent from '@/components/account_values/AccountValueComponent.vue'
import CommentComponent from '@/components/comments/CommentComponent.vue'

export default {
    created() {
        this.setObject()
    },

    components: {
       MainComponent,
       AccountValueComponent,
       CommentComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () { 
            if(this.item.id != this.object.id){
                this.setTab()
            }  
            this.setObject()
        },
        
        item () {   
            this.setObject()
        }
    },
    
    data () {
        return {
            active_tab: 'tab-1',
            item: {},
            account_values: {
                module: 'financials',
                model: 'tenants',
                item_id: '',
                items: []
            },

            comments: {
                model: "tenants",
                item_id: "",
                items: []   
            },
        }
    },

    methods: {
        setTab(){
            this.active_tab = 'tab-1'
        },
        
        loadItem(id){
            this.$emit('callLoadItem', id)
        },
    
        setObject(){
            this.item = this.object

            this.account_values.item_id = this.item.id
            this.account_values.items = this.item.json_account_values

            this.comments.item_id = this.item.id
            this.comments.items = this.item.comments
        },
        
    },
}
</script>

<style scoped>

</style>