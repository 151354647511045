<template>
    <div>
        <list-component ref="list_component"></list-component>
    </div>

</template>

<script>
import ListComponent from './partials/ListComponent.vue'

export default {
    name: 'AccountComponent',
    
    components: {
        ListComponent,
    },

    data () {
        return {
          
        }
    },

    methods: {

    },
}
</script>

<style scoped>

</style>