<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.status_payment"
                        :items="items.status_payment"
                        label="Status da mensalidade"
                        outlined
                        dense
                        class="rounded-0"
                        clearable    
                    ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.nome"
                        label="Nome"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="params.url"
                        label="Url"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.date_type"
                        :items="items.date_type"
                        label="Data pesquisa"
                        outlined
                        dense
                        class="rounded-0"  
                        clearable  
                    ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.date_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="params.date_from"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data início</span>
                            </template>
                        </v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="date_from" @input="calendar.date_from = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.date_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="params.date_to"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data fim</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="date_to" @input="calendar.date_to = false"></v-date-picker>

                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        class="rounded-0"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        class="rounded-0"
                    >
                        Pesquisar 
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
export default {
    created(){
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    },

    watch: {
        date_from () {
            this.params.date_from = this.formatDate(this.date_from)
        },

        date_to () {
            this.params.date_to = this.formatDate(this.date_to)
        },
    },

    data() {
        return {
            date_from: '',
            date_to: '',

            calendar: {
                date_from: false,
                date_to: false
            },

            params: {
                id: '',
                date_from: '',
                date_to: '',
            },
            
            items: {
                status_payment: ['A vencer','Vencido','Pago'],
                date_type:['Data vencimento','Data pagamento','Data nota fiscal']
            },
        }
    },

    methods: {
        setObject(){
            if(this.object){
                if(this.object.due_date_from && this.object.due_date_from != ''){
                    this.params.date_type = 'Data vencimento'
                    this.params.date_from = this.object.due_date_from
                }
                if(this.object.due_date_to && this.object.due_date_to != ''){
                    this.params.date_to = this.object.due_date_to
                }
            }
        },

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },
    }
}
</script>