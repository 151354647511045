<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        v-model="item.invoice_name"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome - Nota Fiscal</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        v-model="item.nome_cobranca"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Contato para cobrança</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>    
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone1"
                        :rules="[rules.required]"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 1</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone2"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone 2</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email1"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email2"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail 2</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row> 
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-currency-field
                        v-model="total"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                        readonly
                        background-color="#FFE0B2"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Total</span>
                        </template>
                    </v-currency-field>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-menu
                        v-model="calendar.due_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.due_date"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data vencimento</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="due_date" @input="calendar.due_date = false"></v-date-picker>

                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-menu
                        v-model="calendar.payment_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.payment_date"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data pagamento</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="payment_date" @input="calendar.payment_date = false"></v-date-picker>

                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-menu
                        v-model="calendar.invoice_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.invoice_date"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                            clearable
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data NF</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="invoice_date" @input="calendar.invoice_date = false"></v-date-picker>

                    </v-menu>
                </v-col>
            </v-row>

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>   
    </div>
</template>

<script>

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.object && this.object.id != undefined){
                this.setObject()
            }
        },

        due_date () {
            this.item.due_date = this.formatDate(this.due_date)
        },

        payment_date () {
            this.item.payment_date = this.formatDate(this.payment_date)
        },

        invoice_date () {
            this.item.invoice_date = this.formatDate(this.invoice_date)
        },
    },

    data () {
        return {
            item: {
                due_date: '',
                payment_date: '',
                invoice_date: '' 
            },

            total: 0,

            rules: {
                required: value => !!value || 'Obrigatorio',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            items: {
                status: ['Pendente','Ativo','Inativo','Suspenso'],
            },

            due_date: '',
            payment_date: '',
            invoice_date: '',

            calendar: {
                due_date: false,
                payment_date: false,
                invoice_date: false,
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object
            this.item.due_date = this.formatDate(this.item.due_date)
            this.item.payment_date = this.formatDate(this.item.payment_date)
            this.item.invoice_date = this.formatDate(this.item.invoice_date)
            this.setTotal()
        },

        setTotal(){
            this.total = 0
            if(this.item.json_account_values && this.item.json_account_values.length > 0){
                this.item.json_account_values.forEach(model => {
                    if(model.type == 'Crédito'){
                        this.total += model.value 
                    }
                })
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateAccount', item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>

<style scoped>

</style>