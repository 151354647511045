<template>
    <div>
        <v-form ref="form" class="p-2">
           <v-row dense>
                <v-col v-if="object.module != 'financials'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.type"
                        :items="items.type"
                        outlined
                        :rules="[rules.required]"
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Transação</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-if="object.module == 'financials'"
                        v-model="item.description"
                        :items="items.tenant_descriptions"
                        :rules="[rules.required]"
                        item-text="description"
                        item-value="description"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Descrição</span>
                        </template>
                    </v-select>

                    <v-text-field
                        v-else
                        v-model="item.description"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Descrição</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.description && item.description == 'Outros'" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.description2"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Descrição</span>
                        </template>
                    </v-text-field>
                </v-col>    
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.value"
                        outlined
                        dense
                        :rules="[rules.required, rules.min_value]"
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Valor</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-btn
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                        <span>Adicionar</span>
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                    <template v-slot:[`item.value`]="{ item }">
                        <span :class="$css_report_list">{{ item.value | toCurrency }}</span> 
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>EXCLUIR</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template>
                    
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>  
    </div>
</template>

<script>
    export default {
        components: {
            
        },

        props: {
            object: {
                require: true,
            },
        },

        data () {
            return {
                item: {
                    type: '',
                    description2: ''
                },

                items: {
                    type: ['Crédito','Débito'],
                    tenant_descriptions:[
                        { description: 'Taxa de implantação AYT', content: ''},
                        { description: 'Mensalidade do site e sistema AYT', content: ''},
                        { description: 'Serviço de e-mail - Plano 1 (10 contas 5GB)', content: ''},
                        { description: 'Serviço de e-mail - Plano 2 (20 contas 10GB)', content: ''},
                        { description: 'Serviço de e-mail - Plano 3 (50 contas 15GB)', content: ''},
                        { description: 'Outros', content: ''},
                    ] 
                },

                rules: {
                    required: value => !!value || 'Required.',
                    min_value:  v => (v && v.length >= 5) || 'O valor precisa ser maior que zero (0)',
                },

                headers: [
                    { text: 'DESCRIÇÃO', align: 'start', value: 'description'},
                    { text: 'VALOR', align: 'start', value: 'value'},
                    { text: 'AÇÃO', align: 'start', value: 'actions'}                  
                ],
            }
        },

        methods:{   

            validate () {
                if (this.$refs.form.validate()) {       
                    this.store()
                }
            },
            
            store(){
                this.item.model = this.object.model,
                this.item.item_id = this.object.item_id
                if(this.object.module && this.object.module == 'financials'){
                    this.item.type = 'Crédito'
                }
                if(this.item.description != 'Outros'){
                    this.item.description2 = ''
                }

                if(this.item.description2 != '' && this.item.description == 'Outros'){
                    this.item.description = this.item.description2
                    this.$delete(this.item, "description2");
                    this.item.description2 = ''
                }
                
                this.$store.dispatch('storeAccounValue', this.item)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                                this.$snotify.success('Registrado com sucesso!')
                                this.reset()
                            })
                            .catch(error => {   
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            }) 
            },

            reset(){
                this.item = {}
                this.$refs.form.reset()
            },

            destroy(item){
                this.$store.dispatch('destroyAccounValue', item.id)
                            .then(response => {
                                this.$emit('callLoadItem',this.object.item_id)
                            })
                            .catch(error => {
                                this.$snotify.error('Erro no cadastro')
                                this.errors = error.response.data.errors
                            })
                            .finally(
                                //this.$snotify.remove()
                                this.$snotify.success('Excluído com sucesso!')
                            )
            },
            
        }    
    }
</script>