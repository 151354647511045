export const PROPERTY_ITEMS = [
    // infra
    {order:"1",description:"Água Individualizada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Conjunto,Conjunto Comercial,Flat,Sala Comercial,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"2",description:"Andar inteiro",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Conjunto,Conjunto Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"3",description:"Aquecedor central",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Pousada"},
    {order:"4",description:"Business center",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto Comercial,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Apartamento / Sala Comercial"},
    {order:"5",description:"Cabine primária",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Chácara,Chale,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Predio Comercial,Sala,Sítio"},
    {order:"6",description:"Caseiro",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Chácara,Chale,Fazenda,Pousada,Sítio"},
    {order:"7",description:"Cerca",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Chácara,Chale,Fazenda,Pousada,Sítio"},
    {order:"8",description:"Coffe shop",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Conjunto,Conjunto Comercial,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"10",description:"Controle de acesso ao cond",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Área de Incorporação,Área Industrial,Armazem,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"11",description:"Curral",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Sítio,Casa"},
    {order:"12",description:"Depósito",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Loja,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"13",description:"Depósito sub-solo",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Loja,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"14",description:"Doca",model:"infrastructures",regioes:"Litoral",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Pousada,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"15",description:"Elevador",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Hotel,Predio Comercial,Sala Comercial,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"16",description:"Elevador de carga",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Hotel,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"17",description:"Elevador panorâmico",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Hotel,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"18",description:"Elevador PNE",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Hotel,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"19",description:"Energia elétrica",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Chácara,Sítio"},
    {order:"20",description:"Energia elétrica individualizada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto Comercial,Loja,Ponto Comercial,Predio Comercial,Sala Comercial,Casa,Apartamento / Sala Comercial"},
    {order:"21",description:"Energia trifásica",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto Comercial,Loja,Ponto Comercial,Predio Comercial,Sala Comercial,Casa,Apartamento / Sala Comercial"},
    {order:"22",description:"Entrada de caminhões",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Oportunidade Comerciais,Predio Comercial,Salão,Salão Galpão"},
    {order:"23",description:"Entrada Individualizada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Loja,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"24",description:"Entrada lateral",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Loja,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"25",description:"Escritório/Cowork",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Conjunto Comercial,Flat,Hotel,Pousada,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"26",description:"Esgoto",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Área de Incorporação,Área Industrial,Armazem,Chácara,Fazenda,Sítio"},
    {order:"27",description:"Esquina",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Loja,Oportunidade Comerciais,Ponto Comercial,Sala Comercial,Sobrado,Terreno,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"28",description:"Estacionamento",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"29",description:"Frente mar",model:"infrastructures",regioes:"Litoral",default:"0",tipos:"Apartamento,Assobradada,Casa,Chale,Condominio Fechado,Flat,Hotel,Pousada,Sobrado,Terreno,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"30",description:"Gás encanado",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"31",description:"Gás Individualizado",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"32",description:"Geminada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Sobrado,Casa / Terreno"},
    {order:"33",description:"Gerador",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto Comercial,Loja,Ponto Comercial,Predio Comercial,Sala Comercial,Sobrado,Apartamento / Sala Comercial"},
    {order:"34",description:"Guarita",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sobrado,Terreno,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"35",description:"Heliponto",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Área de Incorporação,Área Industrial,Armazem,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Apartamento / Sala Comercial"},
    {order:"36",description:"Infra internet",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"37",description:"Interfone",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Condominio Fechado,Flat,Sala Comercial,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"38",description:"Lago",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Fazenda,Sítio,Sobrado"},
    {order:"39",description:"Lava rápido",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Conjunto,Conjunto Comercial,Oportunidade Comerciais,Ponto Comercial"},
    {order:"40",description:"Lavabo",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Loja,Oportunidade Comerciais,Ponto Comercial,Sala Comercial,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"41",description:"Lavanderia coletiva",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Hotel,Apartamento / Sala Comercial"},
    {order:"42",description:"Lavoura",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Fazenda,Sítio,Sobrado"},
    {order:"43",description:"Marina",model:"infrastructures",regioes:"Litoral",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Pousada,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"44",description:"Mezanino",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Loja,Oportunidade Comerciais,Ponto Comercial,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"45",description:"Pasto",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Fazenda,Sítio,Sobrado"},
    {order:"46",description:"Patio estacionamento",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"47",description:"Pet care",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial"},
    {order:"48",description:"Pier",model:"infrastructures",regioes:"Litoral",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Pousada,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"49",description:"Piso elevado",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Conjunto Comercial,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"50",description:"Pista pouso",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Fazenda"},
    {order:"51",description:"Poço artesiano",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Fazenda,Sítio,Sobrado"},
    {order:"52",description:"Ponte rolante",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind."},
    {order:"53",description:"Portão duplo de entrada de pedestre",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Loja,Oportunidade Comerciais,Ponto Comercial,Sobrado,Casa / Terreno"},
    {order:"54",description:"Portão duplo de entrada de veiculos",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Loja,Oportunidade Comerciais,Ponto Comercial,Sobrado,Casa / Terreno"},
    {order:"55",description:"Portão eletrônico",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Condominio Fechado,Conjunto,Conjunto Comercial,Loja,Oportunidade Comerciais,Ponto Comercial,Sobrado,Casa / Terreno"},
    {order:"56",description:"Portaria Virtual",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto,Conjunto Comercial,Flat,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"57",description:"Recepção",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Condominio Fechado,Conjunto,Conjunto Comercial,Flat,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Apartamento / Sala Comercial"},
    {order:"58",description:"Rede de telefone",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"59",description:"Refeitório",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"60",description:"Reservatório de água",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Chácara,Chale,Fazenda,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Salão Galpão,Sítio"},
    {order:"61",description:"Restaurante",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Salão Galpão"},
    {order:"62",description:"Rio",model:"infrastructures",regioes:"Campo",default:"0",tipos:"Chácara,Condominio Fechado,Pousada,Fazenda,Sítio,Sobrado"},
    {order:"63",description:"Rua asfaltada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Área de Incorporação,Área Industrial,Armazem,Chácara,Chale,Fazenda,Pousada,Sítio"},
    {order:"64",description:"Sacada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Área de Incorporação,Área Industrial,Armazem,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sítio,Sobrado,Terreno,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"65",description:"Sala de convenções",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Conjunto,Conjunto Comercial,Hotel,Oportunidade Comerciais,Predio Comercial"},
    {order:"66",description:"Segurança 24h",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Flat,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Pousada,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Sítio,Sobrado,Terreno,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"67",description:"Segurança patrimonial",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Condominio Fechado,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"68",description:"Sem elevador",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"69",description:"Sem vaga",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"70",description:"Sistema de incêndio",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"71",description:"Sistema de segurança",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"72",description:"Solarium",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"73",description:"Telefone",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"74",description:"Terraço com piscina",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"75",description:"Terraço gourmet",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"76",description:"TV a cabo",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"77",description:"Vaga coberta",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"78",description:"Vaga coberta e fechada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"79",description:"Vaga demarcada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"80",description:"Vaga descoberta",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"81",description:"Vaga dupla",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"82",description:"Vaga fechada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"83",description:"Vaga livre",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"84",description:"Vaga moto",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"85",description:"Vaga para deficiente",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"86",description:"Vaga visitante",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Condominio Fechado,Flat,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"87",description:"Vão livre",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Conjunto,Conjunto Comercial,Galpão,Galpão c/ Mor.,Galpão Com.,Galpão Ind.,Hotel,Loja,Oportunidade Comerciais,Ponto Comercial,Predio Comercial,Sala Comercial,Salão,Salão Galpão,Apartamento / Sala Comercial"},
    {order:"88",description:"Varanda",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"89",description:"Varanda terraço",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"90",description:"Vestiário",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Armazem,Condominio Fechado,Conjunto,Conjunto Comercial,Fazenda,Flat,Hotel,Oportunidade Comerciais,Pousada,Predio Comercial,Sítio"},
    {order:"248",description:"Semi Geminada",model:"infrastructures",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Assobradada,Casa,Sobrado,Casa / Terreno"},    

    // recreation
    {order:"91",description:"Academia",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"92",description:"Área verde",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"242",description:"Bosque",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"93",description:"Churrasqueira",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"94",description:"Forno de pizza",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"95",description:"Piscina adulto",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"96",description:"Piscina infantil",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"97",description:"Play ground",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"98",description:"Quadra poliesportiva",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"99",description:"Salão de festas",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"100",description:"Salão de jogos",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"101",description:"Sauna",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"102",description:"Brinquedoteca",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"103",description:"Cinema",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"104",description:"Bicicletario",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"105",description:"Piscina aquecida",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"106",description:"Espaço mulher",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"107",description:"Quadra tenis",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"108",description:"Skate",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"109",description:"Espaço zen",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"110",description:"Lanhouse",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:""},
    {order:"111",description:"Espaço teen",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"112",description:"SPA",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"113",description:"Pista de cooper",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"114",description:"Piscina coberta",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"115",description:"Praça",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"116",description:"Canil",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"117",description:"Campo de futebol",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"118",description:"Piscina no imóvel",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"119",description:"Quadra de squash",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"120",description:"Bar",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"121",description:"Campo de golf",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"122",description:"Espaço gourmet",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"251",description:"Clube",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
    {order:"252",description:"Pista de bicicross",model:"recreations",regioes:"Urbana, Litoral, Campo",default:"0",tipos:"Apartamento,Assobradada,Casa,Chácara,Chale,Condominio Fechado,Fazenda,Flat,Hotel,Pousada,Sítio,Sobrado,Apartamento / Sala Comercial,Casa / Terreno"},
   
    // zonings
    {order:"123",description:"ZEU",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"124",description:"ZC",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"125",description:"ZEUP",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"126",description:"ZPI",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"127",description:"ZEM",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"128",description:"ZEPAM",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"129",description:"ZEPAG",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"130",description:"ZEPEC",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"131",description:"ZOE",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"132",description:"ZLT",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"133",description:"ZPDS",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"134",description:"ZEP",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"135",description:"ZER1",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"136",description:"ZER2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"137",description:"ZER3",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"138",description:"ZM1",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"139",description:"ZM2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"140",description:"ZM3-A",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"141",description:"ZM3-B",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"142",description:"ZCP-A",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"143",description:"ZCP-B",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"144",description:"ZER-P",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"145",description:"ZM-P",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"146",description:"ZCP-P",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"147",description:"ZECOR-1",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"148",description:"ZECOR-2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"149",description:"ZECOR-3",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"150",description:"ZECOR-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"151",description:"ZECOR-5",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"152",description:"ZECOR-6",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"153",description:"ZEIS-1",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"154",description:"ZEIS-2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"155",description:"ZEIS-3",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"156",description:"ZEIS-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"157",description:"ZEIS-5",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"158",description:"ZEIS-6",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"159",description:"ZM",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"160",description:"ZM-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"161",description:"ZC-2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"162",description:"ZC-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"163",description:"ZPR",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"164",description:"ZPR",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"165",description:"ZPR",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"166",description:"ZC-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"167",description:"ZC-2",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"168",description:"ZM-4",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"169",description:"ZM",model:"zonings",regioes:null,default:"0",tipos:null},
    {order:"253",description:"JUDI 1",model:"zonings",regioes:null,default:null,tipos:null},
    {order:"255",description:"ZEMP",model:"zonings",regioes:null,default:null,tipos:null},
    {order:"256",description:"ZI",model:"zonings",regioes:null,default:null,tipos:null},
    {order:"257",description:"ZUP-1",model:"zonings",regioes:null,default:null,tipos:null},
    {order:"258",description:"ZDE-2",model:"zonings",regioes:null,default:null,tipos:null},
    
    // private
    {order:"170",description:"Aceita PET",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"171",description:"Acessibilidade",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"172",description:"Adaptado para idoso",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"173",description:"Adega",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"174",description:"Ar condicionado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"175",description:"Área de serviço",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"176",description:"Armário embutido",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"177",description:"Armário na cozinha",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"178",description:"Armário no dormitório",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"178",description:"Banheira",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"179",description:"Churrasqueira",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"180",description:"Claraboia",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"181",description:"Closet",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"182",description:"Copa",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"183",description:"Cozinha",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"184",description:"Cozinha Americana",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"185",description:"Dependência de empregado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"186",description:"Depósito",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"187",description:"Despensa",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"188",description:"Dormitório reversível",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"189",description:"Edicula",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"190",description:"Elevador privativo",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"191",description:"Entrada de serviço",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"192",description:"Entrada lateral",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"193",description:"Envidraçada (varanda / sacada)",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"194",description:"Escritório",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"195",description:"Gesso - Sanca - Teto Rebaixado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"196",description:"Hall",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"197",description:"Hidromassagem",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"198",description:"Home Theater",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"199",description:"Jardim de Inverno",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"200",description:"Lareira",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"201",description:"Lavabo",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"202",description:"Lavanderia",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"203",description:"Living room",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"204",description:"Mobiliado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"205",description:"Piscina Privativa",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"206",description:"Porão",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"207",description:"Quintal",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"208",description:"Rede de Segurança",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"209",description:"Sacada",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"210",description:"Sacada gourmet",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"211",description:"Sacada Grill",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"212",description:"Sala de estar",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"213",description:"Sala de estudo",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"214",description:"Sala de jantar",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"215",description:"Sala de TV",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"216",description:"Sala Intima",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"217",description:"Semi mobiliado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"218",description:"Solarium",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"219",description:"Soton",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"220",description:"Terraço",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"221",description:"Terraço gourmet",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"222",description:"Terraço Grill",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"223",description:"Varanda",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"224",description:"Ventilador de teto",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"225",description:"WC empregado",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"243",description:"Fogão",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"244",description:"Freezer",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"245",description:"Geladeira",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"246",description:"Lavadora de Roupas",model:"private_areas",regioes:null,default:"0",tipos:null,created_at:null,updated_at:null},
    {order:"247",description:"Micro ondas",model:"private_areas",regioes:null,default:"0",tipos:null,created_at:null,updated_at:null},
    {order:"249",description:"TV",model:"private_areas",regioes:null,default:"0",tipos:null},
    {order:"250",description:"Utensilho Cozinha",model:"private_areas",regioes:null,default:"0",tipos:null},
    
    
    // users
    {order:"226",description:"Acesso externo",model:"users",regioes:"acesso",default:"1",tipos:"Gerente, Corretor, Estagiário, Callcenter"},
    {order:"227",description:"Administra locação",model:"users",regioes:"locacao",default:"0",tipos:"Gerente"},
    {order:"228",description:"Acesso externo restrito a carteira",model:"users",regioes:"acesso",default:"0",tipos:"Corretor"},
    {order:"229",description:"Edita imóvel ativo da carteira",model:"users",regioes:"imovel",default:"0",tipos:"Corretor, Estagiário"},
    {order:"230",description:"Ativa imóvel da carteira",model:"users",regioes:"imovel",default:"0",tipos:"Corretor"},
    {order:"231",description:"Acesso aos proprietarios de outras carteiras",model:"users",regioes:"acesso",default:"1",tipos:"Corretor"},
    //{order:"232",description:"Acesso aos proprietarios de outras carteiras externamente",model:"users",regioes:"acesso",default:"1",tipos:"Corretor"},
    {order:"233",description:"Este usuario poderá acessar os imoveis de outra unidade",model:"users",regioes:"acesso",default:"1",tipos:"Gerente, Corretor,Callcenter"},
    {order:"234",description:"Cadastrar FAC",model:"users",regioes:"fac",default:"1",tipos:"Corretor, Estagiário,Callcenter"},
    {order:"235",description:"Cadastrar FAC com telefone / e-mail existente",model:"users",regioes:"fac",default:"0",tipos:"Corretor, Callcenter"},
    {order:"236",description:"Excluir FAC",model:"users",regioes:"fac",default:"0",tipos:"Corretor, Callcenter"},
    {order:"237",description:"Cadastra imóvel",model:"users",regioes:"imovel",default:"1",tipos:"Corretor, Estagiário"},
    {order:"238",description:"Cadastra proposta",model:"users",regioes:"proposta",default:"1",tipos:"Corretor"},
    {order:"239",description:"Edita condomínio",model:"users",regioes:"condominio",default:"1",tipos:"Corretor, Estagiário,Callcenter"},
    {order:"240",description:"Cadastra condomínio",model:"users",regioes:"condominio",default:"1",tipos:"Corretor, Estagiário,Callcenter"},
    {order:"241",description:"Ativa condominio ativo da carteira",model:"users",regioes:"condominio",default:"0",tipos:"Corretor, Estagiário,Callcenter"},
    {order:"242",description:"Cadastra construtora",model:"users",regioes:"construtora",default:"1",tipos:"Corretor,Estagiário,Callcenter"},
    {order:"243",description:"Edita construtora",model:"users",regioes:"construtora",default:"1",tipos:"Corretor,Estagiário,Callcenter"},
]