<template>
    <div>
        <v-row dense>
           <v-col v-for="itm in dashboard.tenants" :key="itm.description" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                >
                    <v-row no-gutters>
                        <v-col>
                           <v-icon x-large :color="itm.description | statusTenant">mdi-account-group-outline</v-icon>
                        </v-col>
                        <v-col class="text-left"> 
                            <router-link :to="{ 
                                    name: 'admin.tenants.index', 
                                    params: {
                                        status: itm.description                       
                                    } 
                            }">
                            <div>
                                <div class="text-subtitle-2" style="color: black;">{{ itm.description }}</div>
                                <div class="text-body-2" style="color: black;">{{ itm.total }}</div>
                            </div>
                            </router-link>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
            <v-col v-for="itm in dashboard.accounts" :key="itm.description" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                >
                    <v-row no-gutters>
                        <v-col>
                           <v-icon x-large :color="itm.description | statusMens ">mdi-account-cash-outline</v-icon>
                        </v-col>
                        <v-col class="text-left"> 
                            <router-link :to="{ 
                                    name: 'admin.accounts.index', 
                                    params: {
                                        status_payment: itm.status                       
                                    } 
                            }">
                            <div>
                                <div class="text-subtitle-2" style="color: black;">{{ itm.description }}</div>
                                <div class="text-body-2" style="color: black;">{{ itm.total }}</div>
                            </div>
                            </router-link>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col :class="$css_show_description" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">        
                Teste 3333
                <calendar-component></calendar-component>
            </v-col>    
        </v-row>

    </div>
</template>

<script>
import CalendarComponent from '../calendars/CalendarComponent.vue'


export default {
    components: {
        CalendarComponent
    },

    created() {
        this.loadDashBoard()
    },

    computed: {
        dashboard() {
            return this.$store.state.dashboard.items.data    
        },
    }, 

    data (){
        return {
            url: process.env.VUE_APP_ROOT_API,
        }    
    },

    methods: {
        loadDashBoard(){
            this.$store.dispatch('loadDashboard') 
        },
    }
}
</script>
