<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">

        <bread-crumb-component :object="breadcrumbs"></bread-crumb-component> 
        
        <v-row class="mt-2">
            <v-col>
                <v-card
                    outlined
                >   
                    <v-form ref="form" class="mt-4 pa-4">
                        <v-row dense> 
                            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                <v-select
                                    v-model="params.status"
                                    :items="items.status"
                                    label="Status"
                                    outlined
                                    dense
                                    :class="$css_field" 
                                    clearable   
                                ></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field
                                    v-model="params.nome"
                                    label="Nome"
                                    outlined
                                    dense
                                    :class="$css_field"
                                    clearable
                                >
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-btn
                                    :block="$vuetify.breakpoint.mobile"
                                    text
                                    color="primary"
                                    @click="loadItems"
                                    :class="$css_field"
                                >
                                Pesquisar
                                </v-btn>
                            </v-col>  
                        </v-row>
                </v-form>       
                </v-card>
            </v-col>
        </v-row>
         
        <div>
            <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Empresa
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Icon new -->
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab dark color="pink" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>New</span>
        </v-tooltip>
        <!-- End Icon new -->
    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent
    },

    computed: {
        listitems() {
            return this.$store.state.tenants.items    
        },
    },    

    data () {
        return {
            item: {},

            items: {
                status: ['Ativo','Pendente','Suspenso','Inativo']
            },

            dialog: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Empresa',
                    disabled: true,
                    href: 'tenants',
                },
            ],

            panel_search: [],

            params: {
                nome: '',
                status: ''
            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
        loadItems () {
            if(this.$route.params.status && this.$route.params.status != ''){
                this.params.status = this.$route.params.status
                this.$route.params.status = ''
            }
            this.$store.dispatch('loadTenants', this.params) 
        },

        loadItem(id){
            this.$store.dispatch('loadTenant', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        newItem(){
            this.item = {}
            this.dialog = true
        },

        editItem (id) {    
            this.$store.dispatch('loadTenant', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },

        openCloseDialog(){
            this.item = {}
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.loadItems()
            }
        },

        submit(){

        },

        clear(){

        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>